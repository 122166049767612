<template>
    <div 
        v-if="requireDetails" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Detalles"
        @click="handleActionClick('DET')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/abstract/abs015.svg" />
        </span>
    </div>

    <div 
        v-if="requireCI" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Captura Inicial" 
        @click="handleActionClick('CI')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/arrows/arr059.svg" />
        </span>
    </div>
    <div 
        v-if="requireCA" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Cancelar" 
        @click="handleActionClick('CA')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/arrows/arr015.svg" />
        </span>
    </div>
    <div 
        v-if="requireCF" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Captura Final" 
        @click="handleActionClick('CF')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/general/gen047.svg" />
        </span>
    </div>
    <div 
        v-if="requireDelete" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Eliminar" 
        @click="handleActionClick('DEL')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/general/gen027.svg" />
        </span>
    </div>

    <div 
        v-if="requireASM" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Autoriza Administrador Embarcación" 
        @click="handleActionClick('ASM')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/arrows/arr086.svg" />
        </span>
    </div>
    <div 
        v-if="requireAWM" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Autoriza Administrador Almacén" 
        @click="handleActionClick('AWM')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/arrows/arr016.svg" />
        </span>
    </div>
    <div 
        v-if="requireDS" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Descargar Solicitud" 
        @click="handleActionClick('DS')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/files/fil008.svg" />
        </span>
    </div>
    <div 
        v-if="requireDM" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Descargar Movimiento" 
        @click="handleActionClick('DM')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/files/fil021.svg" />
        </span>
    </div>
    <div 
        v-if="requireDOC" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Descargar Orden de Compra" 
        @click="handleActionClick('DOC')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/finance/fin009.svg" />
        </span>
    </div>
    <div 
        v-if="requireDelivery" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Finalizar" 
        @click="handleActionClick('DVY')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/general/gen016.svg" />
        </span>
    </div>

    <div 
        v-if="requireCommited" 
        class="btn btn-icon btn-sm btn-active-icon-primary"
        data-bs-toggle="tooltip" 
        data-bs-placement="bottom" 
        title="Finalizar" 
        @click="handleActionClick('CM')"
    >
        <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotune/general/gen016.svg" />
        </span>
    </div>

<div class="btn btn-icon btn-sm btn-active-icon-primary"  v-show="canNegociacionesLiquidation"
    @click="callExtraViewSettlement(row.ID, row.Cells[0].Value, 'liquidationDetail')"
    data-bs-toggle="tooltip" data-bs-placement="bottom" :title="$t('TOOLTIPS.LIQUIDATIONDEAILT')">
    <span class="svg-icon svg-icon-3">
        <inline-svg src="media/icons/duotune/general/gen032.svg" />
    </span>
</div>

</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        fnCallback: {
            type: Function,
            required: true
        },
        rowId: {
            type: String,
            required: true
        },
        requireDetails: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireCA: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireDelete: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireCF: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireASM: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireAWM: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireDOC: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireDM: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireDS: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireCI: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireDelivery: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireCommited: {
            type: Boolean,
            required: false,
            default: () => false
        },
        canNegociacionesLiquidation: {
             type: Boolean,
            default: () => false
        }
    },
    setup(props) {
        const handleActionClick = (action) => {
            if(props.fnCallback) {
                props.fnCallback(action, props.rowId);
            }
        }

        return {
            handleActionClick
        }
    },
})
</script>
